import React from "react"
import "@styles/index.css"
import createRootComponent from "@utils/createRootComponent";

const App = () => {
    return (
        <div className="container pt-10 pl-5 pr-5 pb-10 max-w-2xl flex flex-col">
            <div>
                <div>
                    <div>
                        <h1 className="pb-5">Ukraine, Kyiv, 03124, Gliesereum Ukraine LLC</h1>
                        <h1 className="text-lg text-red-700">
                            Important Notify
                        </h1>
                        <h2 className="text-2xl p-5">
                            Dear Partners, Agents, and Investors,
                        </h2>
                    </div>
                    <div className="p-5">
                        On behalf of Gliesereum Ukraine LLC, I, Pavlo Chabanov, President of the company, hereby notify
                        you
                        of the following:
                        <div className="p-5">
                            On February 22, 2022, our company faced significant challenges due to the full-scale
                            military
                            invasion of Ukraine by the Russian Federation. In response to these circumstances, a
                            decision
                            was made to temporarily suspend all accounts and automated systems on the exchanges Binance,
                            Huobi, FTX, Bybit, as well as sub-accounts and agent/trader accounts officially associated
                            with
                            our company.
                        </div>
                    </div>
                    <div className="p-5">
                        <span className="text-red-700">On June 1, 2024</span>, a resolution was passed to resume the
                        operations of the company's and agents' accounts.
                    </div>
                    <div className="p-5">
                        Each agent is officially authorized under employment agreements to represent the interests of
                        Gliesereum Ukraine LLC in the Digital Assets Department within the European Union and Ukraine.
                        As of <span className="text-red-700">June 5, 2024</span>, Gliesereum Ukraine LLC, in
                        collaboration
                        with competent authorities of Ukraine, the European Union, the United Kingdom, and the United
                        States, is conducting various measures to identify and verify each partner, investor, and agent
                        to
                        ensure they are not involved in financing terrorism or circumventing sanctions imposed on
                        citizens
                        of the Russian Federation within the territories of the European Union, Ukraine, the United
                        States,
                        and the United Kingdom.
                    </div>
                    <div className="p-5">
                        The resumption of our company's operations is of utmost importance to us and our partners.
                        We guarantee the return of all digital assets associated with agents based on signed capital
                        return
                        contracts with thorough verification.
                    </div>
                    <div className="p-5">
                        As of <span className="text-red-700">June 5, 2024</span>, Gliesereum Ukraine LLC issues this
                        notification to inform, identify, and initiate the procedure for direct contract signing for the
                        return of digital assets associated with agents.
                        We request that all parties associated with the agents contact us within ten <span
                        className="text-red-700">(10) days</span> to sign the contracts for the withdrawal of digital
                        assets.
                    </div>
                    <div className="p-5">
                        Additionally, our company will launch the Claim platform, linked to FTX accounts, for the lawful
                        resolution of disputed issues after the ten-day period has expired.
                        <div className="">
                            <a className="text-blue-600" href="https://claim.gliesereum.com" target="_blank">Official
                                link
                                Claim Platform</a>
                        </div>
                        <div className="p-5">
                            If investors do not submit a request for the withdrawal of digital assets by <span
                            className="text-red-700">June 21, 2024</span>, all assets will be transferred to a specially
                            created fund in Ukraine under the control of regulatory authorities.
                        </div>
                    </div>
                    <div>
                        President: Pavlo Chabanov
                    </div>
                    <div>
                        <h1>
                            Gliesereum Ukraine LLC
                        </h1>
                    </div>
                </div>
            </div>
            <div className="mt-10 mb-10">
                <div>
                    Email: <span className="text-green-700">support@gliesereum.com</span>
                </div>
                <div>
                    Signature: <span className="text-sm">{"E22AC9CC002BA2DDA26747DFDB2FC3DD11C8C".toLowerCase()}</span>
                </div>
                <div>
                    Document: <span className="text-sm">{"241D2497982B5C56D1C6F7138B99159F".toLowerCase()}</span>
                </div>
            </div>
            <div>
                <span>© 2023 GLIESEREUM UKRAINE</span>
            </div>
        </div>
    )
}

createRootComponent(window).render(<App/>)



